
import { Notification, MessageTypes } from "../interfaces/proccess";
import Vue from "vue";
export default Vue.extend({
	name: "Alertize",
	props: {},
	data: () => ({
		images: {
			success: `@/assets/icons/success.svg`,
			error: `@/assets/icons/error.svg`,
		},
	}),
	mounted() {},
	computed: {
		notification(): Notification {
			return this.$store.state.proccess.notification;
		},
		showNotification() {
			return Boolean(this.notification?.show);
		},
		getMessage() {
			return this.notification?.message;
		},
		isStringMessage() {
			return typeof this.notification?.message === "string";
		},
		getErrors() {
			return this.$store.state.proccess.errors;
		},
		getType() {
			return this.notification?.type;
		},
		getRedirectTo() {
			return this.notification?.to;
		},
		getTitle() {
			return this.notification?.title;
		},
		getBtnText() {
			return this.notification?.btn_text;
		},
		getBtnTextRedirect() {
			return this.notification?.btn_text_redirect;
		},
		getRedirect() {
			return this.notification?.to_redirect;
		},
		getQuery() {
			return this.notification?.query;
		},
		getParams() {
			return this.notification?.params;
		},
		isSuccess() {
			return this.notification?.type === MessageTypes.SUCCESS;
		},

		isError() {
			return this.notification?.type === MessageTypes.ERROR;
		},

		showRedirect() {
			return this.notification?.show_redirect;
		},

		// matched

		matchedTitle() {
			return {
				success: this.$t("title-success"),
				error: this.$t("title-failed"),
			};
		},

		matchedType() {
			return {
				success: this.$t("continue"),
				error: this.$t("try-again"),
			};
		},

		isAlertize: {
			set(val: Boolean) {
				this.setAlertize(val);
			},
			get() {
				return this.showNotification;
			},
		},
	},
	methods: {
		setAlertize(val: Boolean) {
			this.$store.state.proccess.notification.show = val;
		},
		async resetNotification() {
			return this.$store.dispatch(
				"proccess/setNotification",
				{
					show: false,
					message: "",
					type: "",
					title: "",
					btn_text: "",
				} as Notification,
				{ root: true }
			);
		},
		async actionBtn() {
			const to = this.getRedirectTo;
			await this.resetNotification();
			if (to) {
				return this.$router.push(
					typeof to == "object" ? to : { name: to }
				);
			}
			return;
		},
		async actionRedirectBtn() {
			const to = this.getRedirect;
			const query = this.getQuery;
			const params = this.getParams;
			await this.resetNotification();
			if (to) {
				return this.$router.push(
					typeof to == "object" ? to : {name: to, query: query, params: params}
				);
			}
			return;
		},
	},
});
